<template>
  <section class="text-center p-4">

           
    <div class="text-center">
      <b-img 
        fluid     
        class="mb-2 headerLogo"
        src="img/login-150px.png"
      ></b-img>

    </div>

    <p>
      {{ $t('NEED_TO_REGISTER') }}  
    </p>

    <p>
      {{ $t('NEED_TO_REGISTER2') }}  
    </p>
    
    <a class="btn btn-primary mt-2" target="_blank" :href="register_url">{{ $t('REGISTER') }}</a>
    
  </section>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  computed: {    
    locale() {
      return this.$i18n.locale;
    },
    register_url() {
      if(this.locale == "sv"){
        return  "https://sefos.se/kom-igang/";
      }else{
        return "https://sefos.se/en/kom-igang/";
      }
    }
  }
};
</script>
<style>
</style>
